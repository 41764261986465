export const HEALTH_CONDITIONS = [
  'addictions',
  'asthma-allergies',
  'attention-deficit',
  'autism',
  'bullying',
  'cancer',
  'copd',
  'dementia',
  'dental',
  'diabetes',
  'eating-disorders',
  'epilepsy',
  'fertility-and-period-tracking',
  'gastrointestinal',
  'headaches',
  'hear-and-sight-impairment',
  'heart',
  'hypertension',
  'medication-adherence',
  'menopause',
  'mood-and-depression',
  'multiple-sclerosis',
  'pain',
  'pregnancy',
  'self-harm',
  'sexual-disorders',
  'skin',
  'sleep-and-insomnia',
  'sleep-apnea',
  'stress-and-anxiety',
  'substance-use',
  'urology',
  'nutrition-and-hydration',
  'fitness',
]

export function useRange(start: number, end: number) {
  return Array.from({ length: end - start }, (_, i) => i + start)
}

export function useFormatPrice() {
  const { locale } = useI18n()

  return (price: number) => {
    return new Intl
      .NumberFormat(`${locale.value}-CA`, { style: 'currency', currency: 'USD' })
      .format(price)
  }
}

export function useCapitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function hexToHSL(H: string) {
  // Convert hex to RGB first
  let r = 0
  let g = 0
  let b = 0

  if (H.length === 4) {
    r = Number(`0x${H[1]}${H[1]}`)
    g = Number(`0x${H[2]}${H[2]}`)
    b = Number(`0x${H[3]}${H[3]}`)
  }
  else if (H.length === 7) {
    r = Number(`0x${H[1]}${H[2]}`)
    g = Number(`0x${H[3]}${H[4]}`)
    b = Number(`0x${H[5]}${H[6]}`)
  }
  // Then to HSL
  r /= 255
  g /= 255
  b /= 255
  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin
  let h = 0
  let s = 0
  let l = 0

  if (delta === 0)
    h = 0
  else if (cmax === r)
    h = ((g - b) / delta) % 6
  else if (cmax === g)
    h = (b - r) / delta + 2
  else
    h = (r - g) / delta + 4

  h = Math.round(h * 60)

  if (h < 0)
    h += 360

  l = (cmax + cmin) / 2
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return { h, s, l }
}

export async function usePreloadImage(url: string): Promise<AppGuide.ImageInfo> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = url
    img.onload = () => {
      resolve({
        url,
        width: img.width,
        height: img.height,
      })
    }
    img.onerror = reject
  })
}

export function useVimeoUrl(videoId: string) {
  let videoKey: string | null = null

  if (videoId.includes('/')) {
    const [id, key] = videoId.split('/').filter(v => v !== '')
    videoId = id
    videoKey = key
  }

  const baseUrl = `https://player.vimeo.com/video/${videoId}`
  const params = new URLSearchParams({
    ...(videoKey && { h: videoKey }),
    title: '0',
    byline: '0',
    portrait: '0',
    autoplay: '1',
  })

  return `${baseUrl}?${params.toString()}`
}
